import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"

import SEO from "../../components/SEO"
import Heading from "../../components/Heading"
import Image from "../../components/Image"
import SkuTile from "../../components/SkuTile"

import styles from "./pt.module.scss"

const ValentinesDay = ({ data, location }) => {
  const {
    nodes
  } = data.allProductStyle

  const [allSkus, setAllSkus] = useState([])

  useEffect(() => {
    let reorderedNodes = [
        "forever-85-nappa-pumps",
        "siren-85-nappa-pvc-pumps",
        "oriel-05-lucertolina-flats",
        "alpha-85-naplack-sandals",
        "ardor-55-naplack-sandals",
        "reverse-sandal-75-nappa-sandals",
    ].filter(handle => nodes.find(style => style.handle === handle))
      .map(handle => nodes.find(style => style.handle === handle))

    let updatedSkus = []
    reorderedNodes.forEach(style => {
      style.skus.forEach(sku => {
        sku.handle = style.handle
        if (sku.images.plp2.length > 0)
          updatedSkus.push(sku)
      })
    })
    setAllSkus(updatedSkus)
  }, [nodes])

  return (
    <>
      <SEO
        title="Valentine's Day Edit"
        description="New Collection."
        url={location.pathname}
      />

      <section className={styles.moduleImage6}>
        <Image
          image={{
            desktopImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/Valentines_Day_Edit_Image_1_Desktop_2000x.jpg?v=1707273051",
            mobileImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/Valentines_Day_Edit_Image_1_Mobile_850x.jpg?v=1707273049",
          }}
          altText="Valentine's Day Hero Image"
        />
      </section>

      <section className={styles.moduleText1}>
        <Heading
          tag="h1"
          level="1"
          className={styles.heading}
        >
          Valentine's Day Edit
        </Heading>
        <p className={styles.text}>
          Treat yourself to the ultimate comfort of pillow-top and cloud cushion this Valentine's Day.
        </p>
      </section>

      <ul className={styles.grid}>
        {allSkus.filter(sku => [
          "forever-85-nappa-pumps",
          "siren-85-nappa-pvc-pumps",
          "oriel-05-lucertolina-flats",
        ].includes(sku.handle))
          .map((sku, i) => (
          <SkuTile
            key={i}
            index={i}
            style={nodes.find(style => style.skus.find(s => s.id === sku.id))}
            sku={sku}
            customStyles={styles}
            origin={`collection-valentines`}
          />
        ))}
      </ul>

      <section className={styles.moduleImage2}>
        <Image
          image={{
            desktopImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/Valentines_Day_Edit_Image_2_Desktop_2000x.jpg?v=1707273050",
            mobileImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/Valentines_Day_Edit_Image_2_Mobile_850x.jpg?v=1707273049",
          }}
          altText="Valentine's Day Image"
        />
      </section>

      <ul className={styles.grid}>
        {allSkus.filter(sku => [
          "alpha-85-naplack-sandals",
          "ardor-55-naplack-sandals",
        ].includes(sku.handle))
          .map((sku, i) => (
            <SkuTile
              key={i}
              index={i}
              style={nodes.find(style => style.skus.find(s => s.id === sku.id))}
              sku={sku}
              customStyles={styles}
              origin={`collection-valentines`}
            />
          ))}
      </ul>

      <section className={styles.moduleImage3}>
        <Image
          image={{
            desktopImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/Valentines_Day_Edit_Image_3_Desktop_2000x.jpg?v=1707273049",
            mobileImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/17074584231751100509877328975446.jpg?v=1707459094",
          }}
          altText="Valentine's Day Image"
        />
      </section>

      <ul className={styles.grid}>
        {allSkus.filter(sku => [
          "reverse-sandal-75-nappa-sandals",
        ].includes(sku.handle))
          .map((sku, i) => (
            <SkuTile
              key={i}
              index={i}
              style={nodes.find(style => style.skus.find(s => s.id === sku.id))}
              sku={sku}
              customStyles={styles}
              origin={`collection-valentines`}
            />
          ))}
      </ul>
    </>
  )
}

export default ValentinesDay

export const query = graphql`
    query ValentinesDayQuery {
        allProductStyle(filter: {handle: {in: [
          "forever-85-nappa-pumps",
          "siren-85-nappa-pvc-pumps",
          "oriel-05-lucertolina-flats",
          "alpha-85-naplack-sandals",
          "ardor-55-naplack-sandals",
          "reverse-sandal-75-nappa-sandals",
        ]}}) {
            nodes {
                ...PLPStyleFragment
                handle
            }
        }
    }
`
